import React from 'react';
import { Outlet } from 'react-router-dom'; // Import Outlet
import Navbar from '../../components/Navbar/Navbar';

const LayoutNavbar = () => {
  return (
    <>
      <Navbar />
      <Outlet /> {/* Use Outlet instead of children */}
    </>
  );
};

export default LayoutNavbar;
