import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Hero = () => (
    <div className="hero pt-24 md:pt-32 md:pb-16 ">
        <div className="text-left w-full">
            <div className="flex flex-col md:flex-row max-w-full justify-between px-8 md:px-0 md:pl-44">
                <div className='w-full md:w-3/5'>
                    <h1 className="text-7xl md:text-8xl font-black title tracking-tight text-slate-900 w-full -mt-6 font-sohne-bold">The Bitcoin <br />payment <br /> gateway</h1>
                    <p className="pt-2 py-6 pr-4 md:py-6 md:pr-0 md: md:w-3/4 text-xl mt-4 text-slate-600 md:hero-sub font-medium">For companies that want to accept payments in Bitcoin ₿, we craft enterprise-level solutions to set them up. 
                        <b> Immediate transactions, minimal fees, set-up in 1 minute.</b> </p>
          <div className='flex flex-row space-x-6' >        
<button 
  className="btn btn-accent rounded-full bg-slate-900 text-neutral text-md button-create"
  onClick={() => window.open('https://app.paywithflash.com', '_blank')}
>
  Create your account <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
</button><Link to="/contact" className="font-semibold btn-base-100 text-primary text-slate-900 hover:text-indigo-700 text-sm ml-6 flex flex-row items-center">
    Contact sales 
    <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
</Link>
          </div>
          </div>
        
                <div className='w-3/5 -ml-32  flex justify-end'>
                    <img className='hidden lg:block hero-image -mt-24' src="/images/hero40.png" alt="Bitcoin"  />
                </div>
            </div>
        </div>
    </div>
);

export default Hero;