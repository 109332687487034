import React from 'react';
import MansoCards from './MansoCards';

const NextSection3 = () => (
  <div className="next-section py-4 bg-base-300 pb-32 ">
    <div className="text-left w-full">
      <div className="flex flex-col md:flex-row max-w-full justify-between px-4 md:px-0 md:pl-44">
        <div className='w-full md:w-2/3'>
          <div className='badge mb-4 p-4 border-slate-300 bg-transparent text-slate-600 font-semibold'>Corporate solutions</div>
          <h2 className="text-4xl md:text-5xl text-slate-900 w-full hero-title2">Unparalled experience <br />for merchants and customers.</h2>
          <p className="py-6 text-lg text-slate-700 section-text font-medium">Flash simplifies transactions, minimizes transaction fees, and ensures instant payments via the Lightning Network.</p>
        </div>
        <div className='hidden md:block md:w-1/2 flex justify-end'>
          {/* <img className='w-96 section-img -mt-24' src="/images/test1.gif" alt="Next Section" /> */}
        </div>
      </div>
      <MansoCards />
    </div>
  </div>
);

export default NextSection3;