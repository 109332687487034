import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CarouselCards = () => {
   const cards = [
    { title: 'Point of Sales', image: '/images/icons/Icon_PoS.png', description: 'Enable Bitcoin physical payments from your mobile or tablet.', link: '/pos' },
    { title: 'Subscriptions', image: '/images/icons/Icon_Subscriptions.png', description: 'Manage recurring Bitcoin payments for your subscription services.', link: '/subscriptions' },
    { title: 'Paywalls', image: '/images/icons/Icon_Paywalls.png', description: 'Integrate Bitcoin payments into your paywalls for seamless access control.', link: '/paywalls' },
    { title: 'Widgets', image: '/images/icons/Icon_Widget.png', description: 'Use our widgets to accept Bitcoin payments on your website or app.', link: '/widget' },
    { title: 'Payment links', image: '/images/icons/Icon_Payment_link.png', description: 'Generate Bitcoin payment links for quick and easy transactions.', link: '/payment-links' },
    { title: 'Custom integrations', image: '/images/icons/Icon_Custom_integration.png', description: 'Customize your Bitcoin payment gateway to fit your specific needs.', link: '/custom-integrations' },
];

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const carouselRef = useRef(null);

const scroll = (direction) => {
    const scrollAmount = 320; // Adjust this based on your card width and margin
    carouselRef.current.scrollBy({ left: direction * scrollAmount, behavior: 'smooth' });
    // Delay the check to allow the scroll to complete
    setTimeout(checkScroll, 150);
};

const checkScroll = () => {
    const isScrolled = carouselRef.current.scrollLeft > 1; // Use a small threshold instead of 0
    setCanScrollLeft(isScrolled);
};

    useEffect(() => {
        const setInitialScrollPosition = () => {
            // Adjust this width to match your initial margin
            carouselRef.current.scrollLeft = 44;
        };

        setInitialScrollPosition();
        window.addEventListener('resize', setInitialScrollPosition);
        return () => {
            window.removeEventListener('resize', setInitialScrollPosition);
        };
    }, []);

    // Adjust the left and top properties to position the arrows correctly
    // These values should be set according to the exact layout of your cards and carousel container
    const arrowStyles = {
        leftArrow: {
            position: 'absolute',
            left: '180px',
            bottom: '0px', // Position below the first card
            transform: 'translateX(-50%) translateY(-50%)',
            zIndex: 10,
            opacity: canScrollLeft ? 1 : 0.3, // Low opacity when not possible to scroll left
        },
        rightArrow: {
            position: 'absolute',
            left: '180px',
            bottom: '0px', // Position below the first card
            transform: 'translateX(50%) translateY(-50%)',
            zIndex: 10,
        }
    };

    return (
        <div className="relative w-full overflow-hidden pb-8 -mt-4">
            {/* Carousel container should not exceed the width of the viewport */}
            <div ref={carouselRef} className="carousel flex overflow-x-auto">
                {/* Invisible card to create the initial left margin */}
<div className="carousel-item w-10 sm:w-40" style={{ flexShrink: 0, opacity: 0 }}></div>                {cards.map((card, index) => (
                    <div key={index} className="carousel-item py-8 flex-shrink-0 mr-4 w-80 h-80">
                        <div className="card bg-base-100 shadow-xl items-start justify-start">
                            <figure><img className='w-10 items-start justify-start ml-8 mt-10 z-50' src={card.image} alt={card.title} /></figure>
                            <div className="card-body ">
                                <h2 className="card-title mt-0 font-semibold text-slate-900 text-2xl ">{card.title}</h2>
                                <p className="text-slate-500 font-medium ">{card.description}</p>
<div className="card-actions justify-start">
      <button className="btn btn-primary border-slate-300 rounded-full bg-white text-slate-800 text-sm mt-4 px-3 min-h-2 h-8 shadow-none hover:bg-slate-200 hover:border-slate-200" onClick={() => window.location.href = card.link}>See more <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} /></button>
    </div>  
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={() => scroll(-1)} className="  bg-transparent text-xl" style={arrowStyles.leftArrow}>←</button>
            <button onClick={() => scroll(1)} className="  bg-transparent text-xl ml-2" style={arrowStyles.rightArrow}>→</button>
        </div>
    );
};

export default CarouselCards;
