import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';
import MansoCardsAbout from '../../components/About/MansoCardsAbout';
import WorldSectionAbout from '../../components/About/WorldSectionAbout';
import HeroAbout from '../../components/About/HeroAbout';
import HowToAbout from '../../components/About/HowToAbout';

const About = () => {

    const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 1', paragraph: 'This is the first paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 2', paragraph: 'This is the second paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 3', paragraph: 'This is the third paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 4', paragraph: 'This is the fourth paragraph.' },
    ];
    
    const features = [ 
    "Advanced analytics",
    "User-friendly interface",
    "Secure transactions",
    "24/7 customer support",
    "Customizable themes",
    "Mobile compatibility",
    "Cloud-based storage",
    "Automated updates"
];

    return (
<div className="flex flex-col w-full bg-white relative ">
    <NavbarDark className='w-full' />
    <div className='header flex z-10 relative pb-44' style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: '0% 25vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }}> 

                
               <HeroAbout />
                </div>
            

            <MansoCardsAbout />

            <Logos />
            <WorldSectionAbout />
            <HowToAbout />
            <GetStarted />

        
        
            
        <Footer />
        </div>
    );
};

export default About;
