import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const HeroAbout = () => (
    <div className="hero pt-20 md:pt-32 pb-24">
        <div className="text-left w-full">
            <div className="flex flex-col md:flex-row  max-w-full justify-between pl-8 md:pl-44">
                <div className='w-full md:w-2/5 pr-6'>
                    <h1 className="text-5xl md:text-6xl font-bold tracking-tight text-slate-900 w-full -mt-8 ">The <span className='text-purple'>easiest way to accept Bitcoin </span> as a payment method,<br /> online or physical.</h1>
                    <p className="py-6 text-lg text-slate-600 hero-sub font-medium -mt-1">Open your business to millions of customers<br />
                        </p>
          <div className='flex flex-row space-x-6' >        
<a href="https://app.paywithflash.com" target="_blank" rel="noopener noreferrer" className="btn btn-accent rounded-full bg-slate-900 text-neutral text-md button-create">
    Create your account  
    <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
                        </a>
                        <Link to="/contact" className="font-semibold btn-base-100 text-primary text-slate-900 hover:text-indigo-700 text-sm ml-6 flex flex-row items-center">
    Contact sales 
    <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
</Link>
          </div>
          </div>
        
                <div className='w-full md:w-3/5 ml-0 md:-ml-32  flex justify-end'>
                    <img className='hidden md:block hero-image mt-0 md:-mt-24' src="/images/hero-ab.png" alt="Bitcoin"  />
                </div>
            </div>
        </div>
    </div>
);

export default HeroAbout;