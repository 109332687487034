import React from 'react';
import CarouselCards from './CarouselCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const cards = [
  { image: '/images/uc4.png', title: 'Seamless Integration with Leading Platforms', link: '/integrations', description: 'Quickly integrate Bitcoin payments into your existing online store or website using our integrations' },
  { image: '/images/uc1.png', title: 'Monetize with Custom Widgets', link: '/widget', description: 'Integrate our customizable widgets to monetize content effortlessly, facilitating direct Bitcoin transactions on your site.' },
  { image: '/images/uc3.png', title: 'Enhance Sales with Simple Payment Links', link: '/payment-links', description: 'Use straightforward payment links to improve sales processes, enabling quick and easy Bitcoin purchases.' },
];

const NextSection5 = () => (
  <div className="next-section bg-white py-4 md:py-32 pb-12 pr-4 md:pr-32">
    <div className="text-left min-w-full">
      <div className="w-full px-4 md:pl-44">
        <div className='w-full'>
          <div className='badge mb-4 p-4 border-slate-300 bg-transparent text-slate-600 font-semibold'>Corporate solutions</div>
          <h2 className="text-4xl md:text-5xl w-full text-slate-900 w-full hero-title2">No-code solutions <br /> to start earning Bitcoin in minutes.</h2>
          <p className="py-6 w-full md:w-2/4 text-lg text-slate-700 section-text font-medium">Start earning Bitcoin within minutes with our no-code solutions. Enable easy payments through the Lightning Network with low fees and instant transactions.</p>
        </div>
        <div className='hidden md:block md:w-1/2 flex justify-end'>
          {/* <img className='w-96 section-img -mt-24' src="/images/test1.gif" alt="Next Section" /> */}
        </div>
        <div className="relative w-full overflow-hidden pb-12 -mt-8 -ml-8 ">
          <div className="flex ">
            {cards.map((card, index) => (
              <div key={index} className="carousel-item py-12 pl-8 flex-shrink-0 mr-0 flex-1">
                <div className="card bg-white shadow-xl items-start justify-start">
                  <figure><img className='w-full items-start justify-start   z-50' src={card.image} alt={card.title} /></figure>
                  <div className="card-body ">
                    <h2 className="mt-0 font-semibold text-xl text-slate-900 ">{card.title}</h2>
                    <p className="text-slate-500 font-medium ">{card.description}</p>
    <div className="card-actions justify-start">
      <button className="btn btn-primary border-slate-300 rounded-full bg-white text-slate-800 text-sm mt-4 px-3 min-h-2 h-8 shadow-none hover:bg-slate-200 hover:border-slate-200" onClick={() => window.location.href = card.link}>See more <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} /></button>
    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NextSection5;