import React, { useState } from 'react';

const Email = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false); // New state to handle form visibility

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(''); // Clear any previous message

        try {
            const response = await fetch('https://app.nostreggs.io/api/subscribe_to_mailing_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (response.ok) {
                const data = await response.json();
                // setMessage('Email successfully subscribed!');
                setIsSubscribed(true); // Hide the form and show success message
            } else {
                const errorData = await response.json();
                setMessage(`Error: ${errorData.error}`);
            }
        } catch (error) {
            setMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="email-subscription py-16 bg-gray-100 text-center">
            {!isSubscribed ? (
                <>
                    <h2 className="text-2xl font-semibold text-slate-900 mb-4">Subscribe to receive updates:</h2>
                    <form onSubmit={handleSubmit} className="flex flex-col items-center">
                        <input
                            type="email"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={handleInputChange}
                            className="p-2 rounded-lg border border-gray-300 mb-4 w-72"
                            required
                        />
                        <button
                            type="submit"
                            className="btn btn-primary rounded-full bg-slate-900 text-white px-6 py-2"
                        >
                            Subscribe
                        </button>
                    </form>
                </>
            ) : (
                <div className="success-message">
                    <h2 className="text-2xl font-semibold text-slate-900 mb-4">Thank you for subscribing!</h2>
                    {/* <p className="text-xl text-slate-600">You will receive updates to the email address provided.</p> */}
                </div>
            )}
            {message && <p className="mt-4 text-red-500">{message}</p>}
        </div>
    );
};

export default Email;
