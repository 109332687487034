import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';



const Paywalls = () => {

const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Instant Access Control', paragraph: 'Implement paywalls quickly to manage access to exclusive content.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Flexible Access Settings', paragraph: 'Customize access based on subscription levels or one-time payments.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'User-Friendly Payment Interface', paragraph: 'Provide a smooth payment experience that encourages conversions.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Real-Time Transaction Confirmation', paragraph: 'Verify transactions instantly, granting immediate access to content.' },
];
    
    const features = [ 
    "Rapid paywall integration",
    "Customizable access levels",
    "Smooth payment process",
    "Instant verification",
    "Secure encryption",
    "Dedicated support"
];

    return (
<div className="flex flex-col w-full bg-white relative">            
             <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: '0% 22vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
            }}> 
                <div className='flex min-h-screen flex-col md:flex-row'>
        <div className='w-full md:w-1/2'>
            <PageTitle 
                 title="Paywalls"
                icon="./images/icons/Icon_Paywalls.png"        
                description="Integrate Bitcoin payments into your paywalls for seamless access control." 
              showButton={true} 
                buttonText="Start now →" 
                buttonAction={() => window.open('https://app.paywithflash.com', '_blank')} 
                    />
                    
                </div>
                <div className='w-full md:w-1/2 pr-0 pl-0 md:pb-24 md:pl-0 md:pr-20  h-auto'>
                    <img className='mt-6 md:mt-20  ' src='/images/paywalls-demo.png'></img>
            
                    </div>
                    </div>
                </div>
                
            <FeaturesComponent items={cardItems} />
            <FeaturesBlock
        title="Paywalls: Monetize exclusive content with Bitcoin."
        paragraph="Easily integrate a Bitcoin payment system into your paywalls. This solution is designed for rapid deployment and flexible content access management, enhancing both security and user engagement."
        features={features}
        imageUrl="/images/paywall.jpg"
            />

            <Logos />
            <GetStarted />

        

            
        <Footer />
        </div>
    );
};

export default Paywalls;
