import React from 'react';
import { Outlet } from 'react-router-dom'; // Import Outlet

const LayoutNoNavbar = () => {
  return (
    <Outlet /> 
  );
};

export default LayoutNoNavbar;
