import React from 'react';

const ProductSubMenuMobile = () => (
    <ul className="pb-4 flex flex-wrap   w-full py-6 ">
        <div className="flex flex-col md:grid md:grid-cols-3 gap-2">
            <div>
                <h3 className="text-xs text-slate-500 mb-4 uppercase">Online</h3>
                <li>
                    <div className='flex items-start p-0 mb-2 gap-2 hover:bg-transparent hover:text-indigo-600'>
                        <img src="./images/icons/Icon_Payment_link.png" alt="Payment link icon" className="w-8 mr-1 -mt-1.5"/>
                        <div className='flex flex-col'>
                            <a className='text-left text-slate-900 font-medium mb-2 hover:text-indigo-600' href='/payment-links'>Payment links</a>
                        </div>
                    </div>
                </li>
                            <li>
                 <div className='flex flex-row items-start p-0 mb-2 gap-2 hover:bg-transparent hover:text-indigo-600'>
                    <img src="./images/icons/Icon_Paywalls.png" alt="description_of_image" className="w-8 mr-1 -mt-1.5"/>
                    <div className='flex flex-col gap-0'>
                        <a className='text-left text-slate-900 font-medium mb-2 hover:text-indigo-600' href='/paywalls'>Paywalls</a>
                    </div>
                </div>
            </li>
            <li>
                 <div className='flex flex-row items-start p-0 mb-2 gap-2 hover:bg-transparent hover:text-indigo-600'>
                    <img src="./images/icons/Icon_Subscriptions.png" alt="description_of_image" className="w-8 mr-1 -mt-1.5"/>
                    <div className='flex flex-col gap-0'>
                        <a className='text-left text-slate-900 font-medium mb-2 hover:text-indigo-600' href='/subscriptions'>Subscriptions</a>
                    </div>
                </div>
                </li>
                <li>
                 <div className='flex flex-row items-start p-0 mb-2 gap-2 hover:bg-transparent hover:text-indigo-600'>
                    <img src="./images/icons/Icon_Widget.png" alt="description_of_image" className="w-8 mr-1 -mt-1.5"/>
                    <div className='flex flex-col gap-0'>
                        <a className='text-left text-slate-900 font-medium mb-2 hover:text-indigo-600' href='/widget'>Widget</a>
                    </div>
                </div>
            </li>
            </div>
            <div>
                <h3 className="text-xs text-slate-500 mb-4 uppercase">Physical</h3>
                <li>
                    <div className='flex items-start p-0 mb-2 gap-2 hover:bg-transparent hover:text-indigo-600'>
                        <img src="./images/icons/Icon_PoS.png" alt="Point of Sales icon" className="w-8 mr-1 -mt-1.5"/>
                        <div className='flex flex-col'>
                            <a className='text-left text-slate-900 font-medium mb-2 hover:text-indigo-600' href='/pos'>Point of Sales</a>
                        </div>
                    </div>
                </li>
                {/* Other menu items */}
            </div>
            <div>
                <h3 className="text-xs text-slate-500 mb-4 uppercase">Developers</h3>
                {/* <li>
                    <div className='flex items-start p-0 mb-2 gap-2 hover:bg-transparent hover:text-indigo-600'>
                        <img src="./images/icons/Icon_Documentation.png" alt="Documentation icon" className="w-8 mr-1 -mt-1.5"/>
                        <div className='flex flex-col'>
                            <a className='text-left text-slate-900 font-medium mb-2 hover:text-indigo-600' href='/documentation'>Documentation</a>
                        </div>
                    </div>
                </li> */}
                <li>
                  <div className='flex flex-row items-start p-0 mb-2 gap-2 hover:bg-transparent hover:text-indigo-600'>
                    <img src="./images/icons/Icon_Custom_integration.png" alt="description_of_image" className="w-8 mr-1 -mt-1.5"/>
                    <div className='flex flex-col gap-0'>
                        <a className='text-left text-slate-900 font-medium mb-2 hover:text-indigo-600' href='/custom-integrations'>Custom integration</a>
                    </div>
                </div>
            </li>
            </div>
        </div>
        <div className='text-black mt-6 mb-2'>Any question or request, <a className='text-indigo-600 pr-4' href='/contact'>contact us →</a></div>
    </ul>
);

export default ProductSubMenuMobile;
