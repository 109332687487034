import React from 'react';

const SatsIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M9.00176 17.9469C13.9447 17.9469 17.9518 13.9398 17.9518 8.99688C17.9518 4.05393 13.9447 0.046875 9.00176 0.046875C4.05881 0.046875 0.0517578 4.05393 0.0517578 8.99688C0.0517578 13.9398 4.05881 17.9469 9.00176 17.9469Z" fill="none"/>
      <path d="M11.4877 2.04457L10.3525 1.74316L9.83749 3.68295L10.9727 3.98436L11.4877 2.04457Z" fill="currentColor"/>
      <path d="M8.16105 14.5587L7.02588 14.2573L6.51083 16.1971L7.646 16.4985L8.16105 14.5587Z" fill="currentColor"/>
      <path d="M13.6942 7.74454L13.9956 6.60938L5.90156 4.46025L5.60016 5.59542L13.6942 7.74454Z" fill="currentColor"/>
      <path d="M12.9032 10.7401L13.2046 9.60498L5.11055 7.45586L4.80914 8.59103L12.9032 10.7401Z" fill="currentColor"/>
      <path d="M12.1283 13.6552L12.4297 12.52L4.33564 10.3709L4.03424 11.5061L12.1283 13.6552Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default SatsIcon;