import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';


const Documentation = () => {

    const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 1', paragraph: 'This is the first paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 2', paragraph: 'This is the second paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 3', paragraph: 'This is the third paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 4', paragraph: 'This is the fourth paragraph.' },
    ];
    
    const features = [ 
    "Advanced analytics",
    "User-friendly interface",
    "Secure transactions",
    "24/7 customer support",
    "Customizable themes",
    "Mobile compatibility",
    "Cloud-based storage",
    "Automated updates"
];

    return (
<div className="flex flex-col w-full bg-white relative">
    <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/bg-d.png')`,
        backgroundPosition: '0% 10vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }}>
        <div className='w-1/2'>
            <PageTitle 
                title="Documentation"
                icon="./images/icons/Icon_Documentation.png"        
                description="This is a description of the page." 
                showButton={false} 
                buttonText="Click Me" 
                buttonAction={() => console.log('Button clicked!')} 
                    />
                    
                </div>
                <div className='w-1/2 pr-44 h-screen'>
            
        </div>
                </div>
                
            <FeaturesComponent items={cardItems} />
            <FeaturesBlock
        title="Generate an invoice"
        paragraph="Add a product and customer information to create an invoice. Manually set a tax rate or automatically collect sales taxes."
        features={features}
        buttonText="Get started"
        imageUrl="/images/hero40.png"
            />

            <Logos />
            <GetStarted />

        
        
            
        <Footer />
        </div>
    );
};

export default Documentation;
