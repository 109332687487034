import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';



const Subscriptions = () => {

const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Automated Billing Cycles', paragraph: 'Set up and manage recurring payment requests automatically.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Easy Subscription Management', paragraph: 'Tools to oversee subscriber accounts and payment schedules effortlessly.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Flexible Payment Plans', paragraph: 'Offer various subscription models to meet diverse customer needs.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Consistent Revenue Stream', paragraph: 'Ensure a steady income with reliable and predictable payments.' },
];
    
    const features = [ 
    "Automated payment systems",
    "Simple subscriber management",
    "Varied subscription options",
    "Reliable income assurance",
    "Advanced security protocols",
    "24/7 technical support",
];

    return (
<div className="flex flex-col w-full bg-white relative">            
            <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: '0% 22vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
            }}> 
                <div className='flex min-h-screen flex-col md:flex-row'>
        <div className='w-full md:w-1/2'>
            <PageTitle 
                title="Subscriptions"
                icon="./images/icons/Icon_Subscriptions.png"        
                description="Empower your platform with effortless Bitcoin subscription management." 
                 showButton={true} 
                buttonText="Start now →" 
                buttonAction={() => window.open('https://app.paywithflash.com', '_blank')} 
                    />
                    
                </div>
                <div className='w-full md:w-1/2 pr-0 pl-0 md:pb-24 md:pl-0 md:pr-20  h-auto'>
                    <img className='mt-6 md:mt-20  ' src='/images/subs-demo2.png'></img>
            
                    </div>
                    </div>
                </div>
                
            <FeaturesComponent items={cardItems} />
            <FeaturesBlock
        title="Subscriptions: Streamline Bitcoin payments for recurring services."
        paragraph="Utilize our system to automate and manage recurring Bitcoin payments for your subscription-based services. Designed for simplicity and reliability, it supports diverse business models with customizable payment plans and robust security."
        features={features}
        imageUrl="/images/subs.jpg"
            />

            <Logos />
            <GetStarted />

        

            
        <Footer />
        </div>
    );
};

export default Subscriptions;
