import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const NewIntegration = () => (
  <div className="next-section bg-white py-32 pb-40 md:pb-60" style={{ backgroundImage: `url(/images/bg-8.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom' }}>    <div className="text-left w-full">
      <div className="flex max-w-full justify-between px-6 md:px-44 ">
        <div className='w-full md:w-3/5 pr-12'>
          <h4 className="text-2xl font-bold text-slate-900 w-full">You'd like to suggest or request a custom integration?</h4>
          <p className="py-6 text-lg text-slate-600 section-text font-medium">Make it easy for any of your customers to pay you in Bitcoin, with almost no transaction fees, via the Lightning Network.</p>
<button 
  className="btn btn-accent rounded-full bg-slate-900 text-neutral text-md button-create"
  onClick={() => window.location.href='/contact'}
>
  Contact us  
  <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
</button>
        </div>
        {/* <div className='w-1/5 flex '>

          <div className="card items-start ">
            <figure>
              <img src="/images/icon1.png" alt="Icon" className="w-8 mb-6" />
            </figure>
            <div className="">
              <h2 className="text-md font-bold text-slate-900 mb-2">See pricing</h2>
              <p className="text-md  text-slate-500 mb-2">Integrated per-transaction pricing with no hidden fees.</p>
              <button className="btn bg-transparent text-indigo-600 text-md border-none p-0 shadow-none hover:bg-transparent ">Sign up  <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} /></button>
            </div>
          </div>

        </div>
        <div className='w-1/5 flex'>
<div className="card items-start ">
            <figure>
              <img src="/images/icon3.png" alt="Icon" className="w-8 mb-6" />
            </figure>
            <div className="">
              <h2 className="text-md font-bold text-slate-900 mb-2">Learn more</h2>
              <p className="text-md  text-slate-500 mb-2">Integrated per-transaction pricing with no hidden fees.</p>
              <button className="btn bg-transparent text-indigo-600 text-md border-none p-0 shadow-none hover:bg-transparent ">Contact <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} /></button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </div>
);

export default NewIntegration;