import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';

const FeaturesBlock = ({ title, paragraph, features = [], buttonText, imageUrl }) => {
  const featureList = features.length > 0 ? (
    <div className="grid grid-cols-2 gap-3 pr-2 md:pr-16 mb-4">
      {features.map((feature, index) => (
        <div key={index} className="flex items-center gap-3 w-full">
              <CheckIcon className="h-5 w-5 text-green-700 bg-green-400 bg-opacity-25 font-bold rounded-full p-1 mr-1" />
              <span className='text-slate-800 font-medium text-sm'>{feature}</span>
        </div>
      ))}
    </div>
  ) : null;

  const button = buttonText ? (
    <button className="btn  mt-4 bg-slate-900 rounded-full min-h-2 p-2 px-4 h-8 text-white">{buttonText}</button>
  ) : null;

  return (
    <div className="flex flex-col md:flex-row flex-wrap px-6 md:px-44 py-12 md:py-24 pb-28 bg-slate-50">
      <div className="w-full  md:w-1/2  p-4">
        <h1 className="text-3xl font-bold titles text-slate-800 pt-8">{title}</h1>
        <p className="my-4 font-medium text-slate-600 text-lg mb-6">{paragraph}</p>
        {featureList}
        {button}
      </div>
      <div className="w-full md:w-1/2 mt-6 md:mt-0">
        <img className="rounded-xl mt-0 md:mt-12 ml-0 mr-4 md:ml-12 shadow-lg" src={imageUrl} alt="Invoice visual" />
      </div>
    </div>
  );
};

export default FeaturesBlock;