import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import NewIntegration from '../../components/Integrations/NewIntegration';
import IntegrationsList from '../../components/Integrations/IntegrationsList';


const Integrations = () => {

    const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 1', paragraph: 'This is the first paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 2', paragraph: 'This is the second paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 3', paragraph: 'This is the third paragraph.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Title 4', paragraph: 'This is the fourth paragraph.' },
    ];
    
    const features = [ 
    "Advanced analytics",
    "User-friendly interface",
    "Secure transactions",
    "24/7 customer support",
    "Customizable themes",
    "Mobile compatibility",
    "Cloud-based storage",
    "Automated updates"
];

    return (
<div className="flex flex-col w-full bg-white relative" style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: 'center 24vh ', // Adjust these values as needed
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    }}>
    <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' >
        <div className='w-1/2'>
            <PageTitle 
                title="Integrations"
                icon="./images/icons/Icon_Custom_integration.png"        
                description="Seamless Platform Integrations." 
                showButton={false} 
                buttonText="Click Me" 
                buttonAction={() => console.log('Button clicked!')} 
                    />
                    
                </div>
                <div className='w-1/2 pr-44 '>
            
        </div>
                </div>
                
            <IntegrationsList />
            <NewIntegration />

        
        
            
        <Footer />
        </div>
    );
};

export default Integrations;
