import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';


const CustomIntegrations = () => {

    const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Tailor Your Gateway', paragraph: 'Adapt our Bitcoin payment system to meet your specific business needs.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Easy Developer Integration', paragraph: 'Utilize our straightforward documentation for hassle-free setup.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Flexible for Any Model', paragraph: 'Our gateway flexibly supports diverse business models.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Efficient Invoice Processing', paragraph: 'Automatically generate and manage invoices with ease.' },
    ];
    
    const features = [ 
    "Advanced Analytics",
    "User-Friendly Interface",
    "Secure transactions",
    "24/7 customer support",
    "Customizable themes",
    "Mobile compatibility",
    "Cloud-based storage",
    "Automated updates"
];

    return (
<div className="flex flex-col w-full bg-white relative">
            <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: '0% 22vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
            }}> 
                <div className='flex min-h-screen flex-col md:flex-row'>
        <div className='w-full md:w-1/2'>
                <PageTitle 
                title="Custom Integrations"
                icon="./images/icons/Icon_Custom_integration.png"        
                description="Customize your Bitcoin payment gateway to fit your specific needs." 
                showButton={true} 
                buttonText="Start now →" 
                buttonAction={() => window.open('https://app.paywithflash.com', '_blank')} 
                />
                </div>
                <div className='w-full md:w-1/2 pr-0 pl-0 md:pb-24 md:pl-0 md:pr-20  h-auto'>
                    <img className='mt-6 md:mt-20  ' src='/images/custom-head2.png'></img>
            
                    </div>
                    </div>
                </div>
            <FeaturesComponent items={cardItems} />
            <FeaturesBlock
        title="Seamless Custom Solutions"
        paragraph="Integrate our versatile Bitcoin payment gateway to create a tailor-made solution that fits your business perfectly. Whether you're looking to adapt to a specific market need or incorporate advanced payment options, our gateway offers the flexibility and security your business requires."
        features={features}
        imageUrl="/images/custom.jpg"
            />

            <Logos />
            <GetStarted />

        
        
            
        <Footer />
        </div>
    );
};

export default CustomIntegrations;
