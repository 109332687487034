import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ProductSubMenu from './ProductSubMenu';
import ProductSubMenuMobile from './ProductSubMenuMobile';


const NavbarDark = () => {
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme'));

  const toggleTheme = () => {
    const newTheme = theme === 'flashtheme' ? 'customDark' : 'flashtheme';
    setTheme(newTheme);
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

    return (
  <div className='flex items-center align-middle justify-center w-full z-50'>
  <div className="navbar px-2 md:px-44 items-center align-middle justify-center">
    <div className="navbar-start">
      <div className="dropdown bg-white">
        <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
        </div>
<ul tabIndex={0} className="menu py-4 menu-sm dropdown-content bg-white  active:bg-white mt-3 z-[1] p-2 shadow rounded-box w-vw focus:bg-white">
                <li><a href='/about' >About</a></li>
                   <li><a href='/integrations'>Integrations</a></li>
                <li><a href='/pricing'>Pricing</a></li>
                
          <li>
            <a>Products</a>
            <ProductSubMenuMobile />
          </li>
        </ul>
      </div>
      <a href="/" className="w-40">
        <img src="/images/logo-org.png" alt="Light Mode Logo" className="logo-light w-40" />
              <img src="/images/logo-dark.png" alt="Dark Mode Logo" className="logo-dark w-52" />
              <h6 className='text-xs absolute pl-10 md:pl-12 -mt-1.5 md:-mt-2'>The Bitcoin Payment Gateway</h6>
      </a>
    </div>
    <div className="navbar-center hidden lg:flex w-fit z-50 hover:bg-white focus:bg-white">
      <ul className="menu menu-horizontal px-1 text-slate-900 hover:bg-white focus:bg-white">
        <li className='font-semibold '><a href='/about'>About</a></li>
                  <li className="hover-menu ">
              <a className='font-semibold'>Products</a>
            <ProductSubMenu />
        </li>
        
              <li className='font-semibold'><a href='/integrations'> Integrations</a></li>
                      <li className='font-semibold'><a href='/pricing'> Pricing</a></li>

      </ul>
    </div>
    <div className="navbar-end ">
 
      <a 
  href="https://app.paywithflash.com/signup" 
  target="_blank" 
  rel="noopener noreferrer" 
              className="hidden md:block btn btn-accent ml-4 text-indigo-600 rounded-full button-signup2 bg-transparent border-0 min-h-4 h-10 shadow-none hover:bg-transparent "
              
>
  Create an account 
</a>
<a 
  href="https://app.paywithflash.com/login" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="btn ml-2 shadow-none bg-indigo-600 border-0 rounded-full min-h-4 h-9 px-3 text-white flex items-center justify-center"
>
  Log in <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} />
</a>
       </div>
      </div>
    </div>
  );
};

export default NavbarDark;