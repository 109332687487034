import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../Base/PageTitle';

const PricingComponent = () => {
    const labels = ['Unlimited transactions', 'Unlimited integrations', 'Physical PoS', 'Realtime Analytics', 'Support 7/7']; // Add or remove labels as needed

    return (
        <div className="w-full mx-auto bg-white rounded-xl shadow-md overflow-hidden m-4 mb-32 mt-24">
            <div className="p-8">
                <div className='badge mb-4 p-4 border-slate-300 bg-transparent text-slate-600 font-semibold'>Fix Fee</div>
                <h2 className='text-5xl font-bold text-slate-900'>1,5% <span className='text-lg font-medium font-slate-500'>/transaction</span></h2>
                
                <ul className="list-disc list-inside space-y-3 mt-8">
                    {labels.map((label, i) => (
                        <li key={i} className="flex items-center">
                            <span className="text-slate-200 mr-2">
                                <CheckCircleIcon className='w-6 h-6' />
                            </span>
                            <span>{label}</span>
                        </li>
                    ))}
                </ul>
                <div className='flex flex-row space-x-6 mt-10' >        
<a href="https://app.paywithflash.com" target="_blank" rel="noopener noreferrer" className="btn btn-accent rounded-full bg-slate-900 text-neutral text-md button-create">
    Create your account  
    <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
                    </a>
                    <Link to="/contact" className="font-semibold btn-base-100 text-primary text-slate-900 hover:text-indigo-700 text-sm ml-6 flex flex-row items-center">
                        Contact sales 
                        <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PricingComponent;