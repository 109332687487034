import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    sector: '',
    message: ''
  });
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.paywithflash.com/api/email_from_contact_form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        setSuccess(true);
      } else {
        alert('Failed to send the contact form.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending the contact form.');
    }
  };

  return (
    <div className="w-full mx-auto bg-white rounded-xl border shadow-md overflow-hidden m-4 mb-0 md:mb-32 mt-0 md:mt-24">
      <div className="p-8">
        {success ? (
          <div className="text-center text-green-500 text-lg font-bold">
            Your message has been sent successfully!
          </div>
        ) : (
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className="input input-bordered w-full text-sm border-slate-200"
                  id="firstName"
                  type="text"
                  placeholder="Satoshi"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="input input-bordered w-full text-sm border-slate-200"
                  id="lastName"
                  type="text"
                  placeholder="Nakamoto"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full mt-4 px-3">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  E-mail
                </label>
                <input
                  className="input input-bordered w-full text-sm border-slate-200"
                  id="email"
                  type="email"
                  placeholder="satoshi@nakamoto.com"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-row w-full mb-6 mt-4">
                <div className="w-full md:w-1/2 px-3">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
                    Company Name
                  </label>
                  <input
                    className="input input-bordered w-full text-sm border-slate-200"
                    id="companyName"
                    type="text"
                    placeholder="Your company"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sector">
                    Sector
                  </label>
                  <select
                    className="input input-bordered w-full text-sm border-slate-200"
                    id="sector"
                    value={formData.sector}
                    onChange={handleChange}
                  >
                    <option value="">Select Sector</option>
                    <option value="digitalMarketing">Digital Marketing</option>
                    <option value="sales">Sales</option>
                    <option value="informationTechnology">Information Technology</option>
                    <option value="finance">Finance</option>
                    <option value="healthcare">Healthcare</option>
                    <option value="manufacturing">Manufacturing</option>
                    <option value="education">Education</option>
                    <option value="retail">Retail</option>
                    <option value="construction">Construction</option>
                    <option value="hospitality">Hospitality</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-wrap w-full">
                <div className="w-full px-3">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="textarea h-32 input-bordered w-full border-slate-200"
                    id="message"
                    placeholder="Tell us more about your business problematic."
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-2">
              <div className="w-full">
                <button
                  className="shadow w-full bg-slate-800 hover:bg-slate-900 focus:shadow-outline focus:outline-none text-sm text-white font-bold py-3 px-4 rounded"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
