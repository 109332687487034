import React from 'react';
import PropTypes from 'prop-types';

const FeaturesComponent = ({ items }) => {
  return (
    <div className="px-4 md:px-60 py-8 md:py-24 pb-20   overflow-hidden  bg-transparent  sm:grid sm:grid-cols-4 sm:gap-px sm:divide-y-0">
      {items.map((item, index) => (
        <div
          key={item.title}
          className="group relative  p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
        >
          <div>
            <img src={item.imageUrl} alt={item.title} className=" w-6 object-cover" />
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-lg text-gray-900 ">
              <a href={item.href} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                {item.title}
              </a>
            </h3>
            <p className="mt-2 text-sm text-gray-500">{item.paragraph}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

FeaturesComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      paragraph: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FeaturesComponent;