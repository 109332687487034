import React from 'react';
import CarouselCards from './CarouselCards';

const NextSection = () => (
  <div className="next-section py-32 bg-base-300">
    <div className="text-left w-full">
      <div className="flex flex-col md:flex-row max-w-full justify-between px-4 md:px-44">
        <div className='w-full md:w-4/5'>
          <div className='badge mb-4 p-4 border-slate-300 bg-transparent text-slate-600 font-semibold'>Corporate solutions</div>
          <h2 className="text-4xl md:text-5xl text-slate-900 w-full hero-title2">The easiest way, for any business, to charge in Bitcoin.</h2>
          <p className="py-6 text-lg text-slate-700 section-text font-medium">Make it easy for any of your customers to pay you in Bitcoin, with almost no transaction fees, via the Lightning Network. Receive payments instantly, without the need to request any payout.</p>
        </div>
        <div className='w-full md:w-1/2 flex justify-end'>
          {/* <img className='w-96 section-img -mt-24' src="/images/test1.gif" alt="Next Section" /> */}
        </div>
      </div>
      <CarouselCards />
    </div>
  </div>
);

export default NextSection;