import React from 'react';
import Hero from '../../components/Home/Hero';
import Email from '../../components/Home/Email';
import Navbar from '../../components/Navbar/Navbar';
import NextSection from '../../components/Home/NextSection';
import Logos from '../../components/Blocks/Logos';
import NextSection2 from '../../components/Home/NextSection2';
import NextSection3 from '../../components/Home/NextSection3';
import Footer from '../../components/Footer/Footer';
import NextSection4 from '../../components/Blocks/GetStarted';
import NextSection5 from '../../components/Home/NextSection5';

const Home = () => {
    return (
        <div className='flex flex-col w-full' style={{
            backgroundImage: `url(/images/22.png)`, backgroundSize: 'contain', backgroundPosition: 'center 0%', backgroundRepeat: 'no-repeat'
        }}>
            <Navbar className='w-full' />
            <Hero />
            <Email />
            <Logos />
            <NextSection />
            <NextSection3 />
            <NextSection2 />
            <NextSection5 />
            <NextSection4 />
            <Footer />

        </div>
    );
};

export default Home;