import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const GetStarted = () => (
  <div className="next-section bg-white py-24 px-6 pb-48 md:pt-40 " style={{ backgroundImage: `url(/images/bg-8.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom' }}>    
    <div className="text-left w-full">
      <div className="flex flex-col md:flex-row max-w-full justify-between px-4 md:px-44 ">
        <div className='w-full md:w-2/5 pr-12 mb-20 '>
          <h4 className="text-3xl md:text-2xl font-bold text-slate-900 w-full">Ready to get started?</h4>
          <p className="py-6 text-lg text-slate-600 section-text font-medium">Make it easy for any of your customers to pay you in Bitcoin, with almost no transaction fees, via the Lightning Network.</p>
<button 
  className="btn btn-accent rounded-full bg-slate-900 text-neutral text-md button-create"
  onClick={() => window.open('https://app.paywithflash.com', '_blank')}
>
  Start now <FontAwesomeIcon className='ml-2 w-2' icon={faChevronRight} />
          </button>
<button 
  className="btn bg-transparent text-slate-900 text-md border-none hover:text-indigo-700 p-0 shadow-none ml-4 hover:bg-transparent"
  onClick={() => window.location.href='/contact'}
>
  Contact sales <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} />
</button>    </div>
        <div className='w-full md:w-1/5 flex flex-col md:flex-row'>
          <div className="card items-start ">
            <figure>
              <img src="/images/icon1.png" alt="Icon" className="w-8 mb-6" />
            </figure>
            <div className="">
              <h2 className="text-md font-bold text-slate-900 mb-2 ">See pricing</h2>
              <p className="text-md  text-slate-500 mb-2">Discover our clear, per-transaction pricing without any hidden fees.</p>
<button 
  className="btn bg-transparent text-indigo-600 text-md border-none hover:text-indigo-900 p-0 shadow-none hover:bg-transparent"
  onClick={() => window.location.href='/pricing'}
>
  See pricing <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} />
</button>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/5 flex flex-col md:flex-row mt-12 md:mt-0'>
          <div className="card items-start ">
            <figure>
              <img src="/images/icon3.png" alt="Icon" className="w-8 mb-6" />
            </figure>
            <div className="">
              <h2 className="text-md font-bold text-slate-900 mb-2">Integrations</h2>
              <p className="text-md  text-slate-500 mb-2">Find out how to easily integrate our solution with your business platforms.</p>
<button 
  className="btn bg-transparent text-indigo-600 text-md border-none p-0 shadow-none hover:text-indigo-900 hover:bg-transparent"
  onClick={() => window.location.href='/integrations'}
>
                See integrations
                <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GetStarted;