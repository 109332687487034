import React from 'react';

const Logos = () => (
    <div className="logos py-8 md:py-20 md:py-28 px-4 md:px-56">
        <div className="flex flex-wrap justify-center">
            {Array.from({ length: 8 }, (_, i) => (
                <div key={i} className="w-1/3 md:w-1/4 py-2 p-4 md:px-12 text-center">
                    <img src={`/images/logo${i + 1}.png`} alt={`Logo ${i + 1}`} />
                </div>
            ))}
        </div>
    </div>
);

export default Logos;

