import React from 'react';

const SignInForm = () => {
    return (
        <div className="flex flex-wrap justify-center">
            <div className="w-full max-w-sm">
                <form className="card w-96 bg-base-100 shadow-xl p-8">
                    <h2 className="text-2xl font-bold mb-5">Sign In</h2>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Username
                        </label>
                        <input
                            className="input input-bordered w-full"
                            id="username"
                            type="text"
                            placeholder="Username"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input
                            className="input input-bordered w-full "
                            id="password"
                            type="password"
                            placeholder="******************"
                        />
                    </div>
                    <div className="form-control mb-4">
                        <label className="label cursor-pointer justify-start">
                        <input type="checkbox" className="toggle toggle-primary"  />
                        <span className="label-text ml-4">Remember me</span> 
                    </label>
                    </div>
                    <div className="flex flex-col items-center justify-between">
                        <div className='w-full'>
                        <button
                            className="btn btn-primary text-white w-full"
                            type="button"
                        >
                            Sign In
                            </button>
                            <button
                            className="btn  bg-indigo-700 hover:bg-indigo-800 text-white w-full mt-2"
                            type="button"
                        >
                            Continue with Nostr
                            </button>
                            </div>
                        <a className="inline-block align-baseline font-medium mt-4 text-sm text-primary hover:text-blue-800" href="#">
                            Forgot Password?
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignInForm;