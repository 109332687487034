import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';


const PoS = () => {

    const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Instant payment', paragraph: 'Instant transactions ensure swift payment processing.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Add your products', paragraph: 'Easily list your products for quick integration.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'With any phone/tablet', paragraph: 'Compatible with all mobile devices for business on-the-go.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Amount in Sats or USD', paragraph: 'Flexible pricing in Bitcoin or USD for convenience.' },
    ];
    
    const features = [
        "Instant Bitcoin payments",
        "Easy product integration",
        "Universal device compatibility",
        "Flexible pricing options",
        "Secure transaction processing",
        "24/7 customer support",
        "Real-time analytics",
        "Automatic software updates"
    ];

    return (
<div className="flex flex-col w-full bg-white relative">
    <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: '0% 22vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
            }}> 
                <div className='flex min-h-screen flex-col md:flex-row'>
        <div className='w-full md:w-1/2'>
            <PageTitle 
                title="Point of Sales"
                icon="./images/icons/Icon_PoS.png"        
                description="Accept Bitcoin for your physical store, directly from your phone." 
                 showButton={true} 
                buttonText="Start now →" 
                buttonAction={() => window.open('https://app.paywithflash.com', '_blank')} 
                    />
                    
                </div>
                <div className='w-full md:w-1/2 pr-0 pl-0 md:pb-24 md:pl-0 md:pr-20  h-auto'>
                    <img className='mt-6 md:mt-20  ' src='/images/pos-demo3.png'></img>
            
                    </div>
                    </div>
                </div>
                
            <FeaturesComponent items={cardItems} />
            <FeaturesBlock
        title="Point of Sales: Accept Bitcoin for your physical store, directly from your phone or tablet."
        paragraph="Transform any smartphone or tablet into a Bitcoin PoS. Simple setup to start accepting Bitcoin payments instantly."
        features={features}
        imageUrl="/images/img-pos.png"
            />

            <Logos />
            <GetStarted />

        
        
            
        <Footer />
        </div>
    );
};

export default PoS;
