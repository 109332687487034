import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';



const PaymentLink = () => {

const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Immediate Link Creation', paragraph: 'Create and share payment links instantly for immediate use.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Customizable Link Options', paragraph: 'Tailor payment conditions to fit specific transactions and customer needs.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Secure Transaction Environment', paragraph: 'Ensure secure payments with encrypted transaction protocols.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Multi-Currency Support', paragraph: 'Display amounts in Bitcoin or USD to accommodate diverse customer preferences.' },
];
    
    const features = [ 
    "Quick link generation",
    "Flexible customization",
    "High-security standards",
    "Multiple currency choices",
    "Efficient tracking and management",
    "24/7 customer support",
];

    return (
<div className="flex flex-col w-full bg-white relative">
            <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: '0% 22vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
            }}> 
                <div className='flex min-h-screen flex-col md:flex-row'>
        <div className='w-full md:w-1/2'>
            <PageTitle 
                title="Payment Links"
                icon="./images/icons/Icon_Payment_link.png"        
                description="Generate Bitcoin payment links for quick and easy transactions." 
                 showButton={true} 
                buttonText="Start now →" 
                buttonAction={() => window.open('https://app.paywithflash.com', '_blank')} 
                    />
                    
                </div>
                <div className='w-full md:w-1/2 pr-0 pl-0 md:pb-24 md:pl-0 md:pr-20  h-auto'>
                    <img className='mt-6 md:mt-20  ' src='/images/link-head.png'></img>
            
                    </div>
                    </div>
                </div>
            
            <FeaturesComponent items={cardItems} />
            <FeaturesBlock
        title="Payment Links: Fast, secure, and flexible way to receive Bitcoin payments."
        paragraph="Craft personalized payment links for various transaction needs. This feature supports instantaneous sending and receiving, enhancing customer convenience with robust security measures."
        features={features}
        imageUrl="/images/link.jpg"
            />

            <Logos />
            <GetStarted />

        

            
        <Footer />
        </div>
    );
};

export default PaymentLink;
