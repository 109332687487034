import React from 'react';

const Footer = () => (
  <div>
<footer className="footer px-8 md:px-24 lg:px-44 py-8 md:py-28 bg-white text-base-content">
  <aside>
    <img src="/images/logo-org.png" alt="Light Mode Logo" className="logo-light w-32" />
  </aside>
  <nav>
    <h6 className="footer-title">Products</h6> 
    <a href="/payment-links" className="link link-hover">Payment Links</a>
    <a href="/paywalls" className="link link-hover">Paywalls</a>
    <a href="/subscriptions" className="link link-hover">Subscriptions</a>
    <a href="/widget" className="link link-hover">Widget</a>
    <a href="/pos" className="link link-hover">Point of Sales</a>
    <a href="/custom-integrations" className="link link-hover">Custom Integrations</a>
  </nav> 
  <nav>
    <h6 className="footer-title">More Links</h6> 
    <a href="/about" className="link link-hover">About</a>
    <a href="/integrations" className="link link-hover">Integrations</a>
    <a href="/pricing" className="link link-hover">Pricing</a>
    <a href="/contact" className="link link-hover">Contact us</a>
    <a href="/" className="link link-hover">Jobs</a>
  </nav> 
  <nav>
    <h6 className="footer-title">Legal</h6> 
    <a href="/terms-of-use" className="link link-hover">Terms of use</a>
    <a href="/privacy-policy" className="link link-hover">Privacy policy</a>
        <a href="/cookie-policy" className="link link-hover">Cookie policy</a>
        <a href="#" class="termly-display-preferences">Consent Preferences</a>
  </nav>
</footer>
  <div className='py-4 text-left px-8 md:text-center w-full text-xs text-slate-600'>©️ Flash Lightning Solutions. All Rights Reserved 2024. <br /> Flash is not a financial company, it is a payment gateway software that connect wallets between themselves. We never touch your funds.</div>
  </div>
);

export default Footer;