import React from 'react';

const PageTitle = ({ title, description, showButton, buttonText, icon, buttonAction }) => (
  <div className="pageTitle px-4 md:px-44 ml-2 py-4 md:py-24">
    <img src={icon} className='w-12 mb-2'></img>
    <h1 className='text-5xl md:text-6xl font-bold text-slate-900 titles'>{title}</h1>
    <p className='text-sm md:text-lg mt-4 font-medium text-slate-500'>{description}</p>
    {showButton && <button className='btn px-3 py-1 min-h-2 h-auto border-none text-sm mt-4 font-medium text-white bg-slate-800 rounded-full hover:text-slate-800 hover:bg-slate-200' onClick={buttonAction}>{buttonText}</button>}
  </div>
);

export default PageTitle;