import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const WorldSectionAbout = () => (
  <div className="next-section2 video-section pt-28 pb-28 relative">
    {/* <video autoPlay loop muted className="absolute w-full bottom-0 object-contain">
      <source src="/images/video-bg4.mp4" type="video/mp4" />
    </video> */}
    <div className="text-left w-full relative mb-90">
      <div className="flex flex-col md:flex-row max-w-full justify-center px-4 md:px-44">
        <div className='w-full text-center'>
          {/* <div className='badge bg-transparent border-slate-400 text-slate-200 mb-4 p-4'>Bitcoin adoption</div> */}
          <h2 className="text-5xl text-white w-full hero-title2 -mt-2">Open your business <br />to 100+ millions customers.</h2>
          <p className="py-6 md:px-60 px-2 pb-90 text-lg text-slate-400 section-text font-medium">Empower your customers to transact in Bitcoin effortlessly, enjoying minimal transaction fees, through the Lightning Network. Experience the convenience of instant payments, without the need for payout requests.</p>
<button 
  className="btn btn-primary border-slate-600 rounded-full bg-slate-900 border-none text-white text-sm mt-2 px-4 py-0 h-6 min-h-10 shadow-none hover:bg-slate-200 hover:text-slate-900 hover:border-slate-200"
  onClick={() => window.location.href='/about'}
>
  Learn more <FontAwesomeIcon className='ml-0 w-2' icon={faChevronRight} />
</button>        </div>
      </div>
    </div>
  </div>
);

export default WorldSectionAbout;