import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import ContactForm from '../../components/Blocks/ContactForm';
import NextSection4 from '../../components/Blocks/GetStarted';
import DarkFeatures from '../../components/Blocks/DarkFeatures';
import Logos from '../../components/Blocks/Logos';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';

const Contact = () => {
    // Example data for CardComponent, replace with actual data
    const cardItems = [
        { imageUrl: 'images/icon1.png', title: 'Title 1', paragraph: 'This is the first paragraph.' },
        { imageUrl: 'images/icon1.png', title: 'Title 2', paragraph: 'This is the second paragraph.' },
        { imageUrl: 'images/icon1.png', title: 'Title 3', paragraph: 'This is the third paragraph.' },
        { imageUrl: 'images/icon1.png', title: 'Title 4', paragraph: 'This is the fourth paragraph.' },
    ];

const features = [ 
    "Advanced analytics",
    "User-friendly interface",
    "Secure transactions",
    "24/7 customer support",
    "Customizable themes",
    "Mobile compatibility",
    "Cloud-based storage",
    "Automated updates"
];

    return (
<div className="flex flex-col w-full bg-white relative">
    <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/bg-d.png')`,
        backgroundPosition: '40% 120%', // Adjust these values as needed
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
            }}>
                <div className='flex flex-col md:flex-row'>
        <div className='w-full md:w-1/2'>
            <PageTitle 
                title="Tell us more about your business." 
                description="Get in touch with us for any question or custom integration." 
                showButton={false} 
                buttonText="Click Me" 
                buttonAction={() => console.log('Button clicked!')} 
            />
        </div>
        <div className='w-full md:w-1/2 pr-4 md:pr-44 pl-4 mt-4 md:mt-0'>
            <ContactForm />
                    </div>
                    </div>
    </div>
    

            
                        <Logos />

            <NextSection4 />
            

            <Footer />
        </div>
    );
};

export default Contact;
