import React from 'react';

// Single Masonry card component
const MasonryCard = ({ title, description, image, badgeImage, badgeText }) => {
    return (
        <div className="masonry-card border-0 bg-white rounded-lg p-2 pb-4 shadow-lg">

            <img src={image} alt={title} className="w-full  object-cover rounded-lg" />
            <div className='p-6'>
            <div className="badge badge-primary rounded-lg px-3 py-5 text-slate-700 font-medium bg-slate-100 border-0 mb-4 mt-2">
                <img src={badgeImage} alt={badgeText} className="w-5  mr-2" />
                <span>{badgeText}</span>
            </div>
                <h2 className='text-2xl font-bold text-slate-900 mb-2'>{title}</h2>
                <p className='text-lg  text-slate-600'>{description}</p>
            </div>
        </div>
    );
};

// The overall Masonry layout component that wraps individual cards
const MansoCards = () => {
const cardsData = [
    { title: 'Implement in your solution', description: 'Integrate into any of your existing solution, or connect through an integration.', image: '/images/step2.png', badgeImage: '/images/icon1.png', badgeText: 'Integrations' },
    { title: 'Manage revenue', description: 'Manage your revenue efficiently with realtime analytics and reports.', image: '/images/step4.png', badgeImage: '/images/icon1.png', badgeText: 'Merchant Account' },
    { title: 'Connect a wallet', description: 'Connect your Lightning wallet to our gateway and start accepting Bitcoin payments.', image: '/images/step1.png', badgeImage: '/images/icon1.png', badgeText: 'Lightning wallets' },
    { title: 'Sell to consumers', description: 'Use our checkout feature to sell directly to consumers and accept Bitcoin payments.', image: '/images/step3.png', badgeImage: '/images/icon1.png', badgeText: 'Checkout' },
];

    return (
        <div className="masonry-layout px-4 md:px-44">
            <div className="column left mb-12 md:mb-0">
                <MasonryCard {...cardsData[0]} />
                <MasonryCard {...cardsData[1]} />
            </div>
            <div className="column right ">
                <MasonryCard {...cardsData[2]} />
                <MasonryCard {...cardsData[3]} />
            </div>
        </div>
    );
};

export default MansoCards;