import { useState, useEffect } from 'react';
import SatsIcon from '../Base/SatsIcon';

const CustomInputWithLocalState = ({ value, onChange, onBlur, onFocus, placeholder, className, selectedCurrency }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value); // Update the local state when the value prop changes
  }, [value]);

  const handleBlur = (e) => {
    onBlur(e);
    onChange(e); // update parent state when input loses focus
  };

const handleChange = (e) => {
  setLocalValue(e.target.value); // update local state on input change
  onChange(e); // update parent state on input change
};

  return (
    <div className="input-group w-full" style={{ position: 'relative' }}>
        <input
        type="text"
        placeholder={placeholder}
        className={`input input-bordered h-10 text-sm w-full border-base-200  ${className}`}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        style={{ paddingLeft: '30px' }} // Add left padding
        />
<span className="input-icon" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}>
  {selectedCurrency === 'USD' ? 
    <span style={{ fontWeight: '500', opacity: 0.9}}>$</span> : 
    <SatsIcon className='w-5 text-primary -ml-1' />
  }
</span>
    </div>
  );
};

export default CustomInputWithLocalState;