// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LayoutNavbar from './pages/Layouts/LayoutNavbar';
import LogIn from './pages/Other/LogIn';
import Home from './pages/Standard/Home';
import LayoutNoNavbar from './pages/Layouts/LayoutNoNavbar';
import Contact from './pages/Standard/Contact';
import PaymentLink from './pages/Products/PaymentLinks';
import Paywalls from './pages/Products/Paywalls';
import Widget from './pages/Products/Widget';
import Subscriptions from './pages/Products/Subscriptions';
import PoS from './pages/Products/PoS';
import Documentation from './pages/Standard/Documentation';
import CustomIntegrations from './pages/Products/CustomIntegrations';
import About from './pages/Standard/About';
import Integrations from './pages/Standard/Integrations';
import Pricing from './pages/Standard/Pricing';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import CookiePolicy from './pages/Legal/CookiePolicy';
import TermsOfUse from './pages/Legal/TermsOfUse';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Route for the Home page with Navbar */}
        <Route element={<LayoutNavbar />}>
          <Route path="/login" element={<LogIn />} />
        </Route>
        <Route element={<LayoutNoNavbar />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/payment-links" element={<PaymentLink />} />
          <Route path="/pos" element={<PoS />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/widget" element={<Widget />} />
          <Route path="/paywalls" element={<Paywalls />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/custom-integrations" element={<CustomIntegrations />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />



        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
