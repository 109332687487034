// Cards.js
import React from 'react';

const IntegrationsList = () => {
    const cardsData = [
        {
            image: '/images/integrations/vimeo.png',
            title: 'Vimeo',
            description: 'Enable Bitcoin payments for video content or streaming platform.',
            status: 1
        },
        {
            image: '/images/integrations/shopify.png',
            title: 'Shopify',
            description: 'Add a Bitcoin checkout to your Shopify online store.',
             status: 2
        },
        {
            image: '/images/integrations/woocommerce.png',
            title: 'WooCommerce',
            description: 'Integrate Bitcoin payments seamlessly with Wordpress.'
        },
        {
            image: '/images/integrations/wix.png',
            title: 'Wix',
            description: 'Accept Bitcoin on your Wix website easily.'
        },
        {
            image: '/images/integrations/zapier.png',
            title: 'Zapier',
            description: 'Automate Bitcoin transactions with Zapier workflows.'
        },
          {
            image: '/images/integrations/magento.png',
            title: 'Magento',
            description: 'Deploy Bitcoin payment solutions in Magento stores.'
        },
        // Add more cards as needed
    ];

     return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-6 md:px-44 mt-16 md:-mt-12 pb-24">
            {cardsData.map((card, index) => (
                <div key={index} className="card bg-base-100 shadow">
                    <figure>
                        <img src={card.image} alt={card.title}  />
                    </figure>
                    <div className="card-body">
                        <h2 className="card-title">{card.title}</h2>
                        <p className='mb-3'>{card.description}</p>
                        {card.status === 1 ? (
                            <button className="btn w-fit shadow-none py-2 px-4 bg-indigo-600 text-white border-none hover:bg-indigo-600 cursor-default min-h-0 h-auto rounded-full ">Live</button>
                        ) : (
                            <button className="btn w-fit shadow-none py-2 px-4 bg-slate-100 text-slate-300 border-none hover:bg-indigo-600 cursor-default min-h-0 h-auto rounded-full">Soon</button>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default IntegrationsList;