import React from 'react';
import CarouselCards from '../Home/CarouselCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const cards = [
  { image: '/images/ht1.jpg', title: 'Create an account for your business or product.', description: 'Step 1' },
  { image: '/images/ht2.jpg', title: 'Connect a Bitcoin Lightning wallet.', description: 'Step 2' },
  { image: '/images/ht3.jpg', title: 'Start selling with Bitcoin as a payment method.', description: 'Step 3' },
];

const PricingFeatures = () => (
  <div className="next-section bg-transparent md:py-32 pt-12 pb-12 md:pr-32">
    <div className="text-left min-w-full">
      <div className=" pl-6  md:pl-44 pr-8 md:pr-0">
        <div className='w-full'>
          <div className='badge mb-4 p-4 border-slate-300 bg-transparent text-slate-600 font-semibold'>Simple set up</div>
          <h2 className="text-5xl w-full text-slate-900 w-full hero-title2">How it works</h2>
          <p className="py-6 w-full md:w-2/4 text-lg text-slate-700 section-text font-medium">Make it easy for any of your customers to pay you in Bitcoin, with almost no transaction fees, via the Lightning Network. Receive payments instantly, without the need to request any payout.</p>
        </div>
        <div className='w-full md:w-1/2 flex justify-end'>
          {/* <img className='w-96 section-img -mt-24' src="/images/test1.gif" alt="Next Section" /> */}
        </div>
        <div className="relative w-full  pb-12 -mt-8 -ml-8 ">
          <div className="flex flex-col w-full md:flex-row ">
            {cards.map((card, index) => (
              <div key={index} className="carousel-item w-full py-6 md:py-12 pl-8 flex-shrink-0 mr-0 flex-1">
                <div className="card bg-white shadow-xl items-start justify-start">
                  <figure><img className='w-full items-start justify-start   z-50' src={card.image} alt={card.title} /></figure>
                  <div className="card-body ">
                  <p className="text-slate-500 font-medium ">{card.description}</p>
                    <h2 className="mt-0 font-semibold text-xl text-slate-900 ">{card.title}</h2>
                 
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PricingFeatures;