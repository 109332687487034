import React from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/Base/PageTitle';
import FeaturesComponent from '../../components/Blocks/FeaturesComponent';
import FeaturesBlock from '../../components/Blocks/FeaturesBlock';
import Logos from '../../components/Blocks/Logos';
import GetStarted from '../../components/Blocks/GetStarted';
import LogoChatToggle from '../../components/Widget/LogoChatToggle';



const Widget = () => {

const cardItems = [
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'One script to add', paragraph: 'Easily integrate with a single line of script.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Any platform', paragraph: 'Seamlessly compatible across all web platforms.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Set up suggested amounts', paragraph: 'Customize suggested Bitcoin donation amounts.' },
    { imageUrl: 'images/icons/Icon_FlashLight.png', title: 'Receive instantly', paragraph: 'Bitcoin donations are transferred instantly.' },
];
    
    const features = [ 
    "Effortless Script Integration",
    "Universal Compatibility",
    "Customizable Support Tiers",
    "Real-Time Alerts",
    "Seamless Integration",
    "User-Friendly Interface",
    "Immediate Fund Transfer",
    "Transparent Analytics"
];

    return (
<div className="flex flex-col w-full bg-white relative">            
             <NavbarDark className='w-full' />
    <div className='header flex z-10 relative' style={{
        backgroundImage: `url('/images/hd.png')`,
        backgroundPosition: '0% 22vh', // Adjust these values as needed
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
            }}> 
                <div className='flex min-h-screen flex-col md:flex-row'>
        <div className='w-full md:w-1/2'>
            <PageTitle 
               title="Widget"
                icon="./images/icons/Icon_Widget.png"        
                description="Implement the Flash widget in your website or blog so that people can support you in Bitcoin." 
                showButton={true} 
                buttonText="Start now →" 
                buttonAction={() => window.open('https://app.paywithflash.com', '_blank')} 
                    />
                    
                </div>
                <div className='w-full md:w-1/2 pr-0 pl-0 md:pb-24 md:pl-0 md:pr-20  h-auto'>
                    <img className='mt-6 md:mt-20  ' src='/images/widget-demo5.png'></img>
            
                    </div>
                    </div>
                </div>
                
            <FeaturesComponent items={cardItems} />
            <FeaturesBlock
        title="Empower your content with Bitcoin support"
        paragraph="Enable Bitcoin donations effortlessly on your site to support your content. Ideal for creators, bloggers, and more."
        features={features}
        imageUrl="/images/widget-img.jpg"
            />

            <Logos />
            <GetStarted />

        
                    <LogoChatToggle />

            
        <Footer />
        </div>
    );
};

export default Widget;
