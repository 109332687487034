import React, { useState } from 'react';
import SatsIcon from '../Base/SatsIcon';
import CustomInputWithLocalState from './CustomInputWithLocalState';
import { InformationCircleIcon } from '@heroicons/react/24/outline';


const LogoChatToggle = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLogoPressed, setIsLogoPressed] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [step, setStep] = useState(1);
  const [showError, setShowError] = useState(false);


  const toggleChat = () => setIsChatOpen(!isChatOpen);
  const handleMouseDown = () => setIsLogoPressed(true);
  const handleMouseUp = () => setIsLogoPressed(false);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [exchangeRate, setExchangeRate] = useState(0.0023432); // Initial exchange rate
  const [customAmountInSats, setCustomAmountInSats] = useState('');
  const usdOptions = ['1', '5', '10'];
  const btcOptions = ['10000', '50000', '100000']; // Replace these with your desired amounts in sats


const handleClick = (currency) => {
  setSelectedCurrency(currency);
  setSelectedAmount(''); // Clear the selected amount
  setCustomAmount(''); // Clear the custom amount input
  setCustomAmountInSats(''); // Clear the custom amount in sats
};

  const handleExchangeRateChange = (event) => {
    setExchangeRate(event.target.value);
  };

  const formatNumber = (num) => {
  return num >= 1000 ? (num/1000).toFixed(0) + 'k' : num;
};

const selectAmount = (amount) => {
  if (selectedAmount === amount) {
    setSelectedAmount('');
    setCustomAmount(''); // Clear the custom amount input if the same badge is clicked again
    setCustomAmountInSats(''); // Clear the custom amount in sats if the same badge is clicked again
  } else {
    setSelectedAmount(amount);
    setCustomAmount(''); // Clear the custom amount input when any badge is selected
    setCustomAmountInSats(amount); // Set the custom amount in sats to the selected amount
  }
};

const handleCustomAmountChange = (e) => {
  const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and dot
  setCustomAmount(value);
  setSelectedAmount(''); // Unselect all the badges
  setCustomAmountInSats(value); // Set the custom amount in sats to the entered value
};

const handleCustomAmountBlur = () => {
  let formattedAmount = customAmount;
  if (selectedCurrency === 'USD') {
    formattedAmount = formatCurrency(customAmount, 'USD', true);
  } else if (selectedCurrency === 'BTC') {
    formattedAmount = formatCurrency(customAmount, 'BTC', true);
  }
  setCustomAmount(formattedAmount);
};

const formatCurrency = (amount, currency, isInput = false) => {
  if (!amount) return '';
  if (currency === 'USD') {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  } else {
    const formattedAmount = new Intl.NumberFormat('en-US', { style: 'decimal' }).format(amount);
    return isInput ? formattedAmount : <><SatsIcon className="w-4 ml-1" />{formattedAmount} </>;
  }
};

const handleCustomAmountFocus = () => {
  // Do nothing when the custom amount input is focused
};

const proceedToPayment = () => {
  let amount = selectedAmount;
  if (customAmount) {
    amount = customAmount; // Use the custom amount if it's entered
    setSelectedAmount(customAmount); // Update selectedAmount to display in step 2
  }

  if (amount) {
    setStep(2);
    setShowError(false); // Hide the error message when an amount is selected
  } else {
    setShowError(true); // Show the error message when no amount is selected
  }
};
  


  const goBack = () => setStep(1);



return (
<div className="logo-chat-container">
  <div className={`logo ${isLogoPressed ? 'pressed' : ''}`} onClick={toggleChat} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
    <img className="chat-icon" src="../images/flash-icon.gif" alt="Logo" />
  </div>
  <div className="text-with-arrow w-44 text-center" style={{ position: 'absolute', top: '-45px', left: '-125px' }}>
      <div className='bg-white p-1 py-2 text-sm font-medium text-slate-800 rounded-xl shadow-lg'>Test the Flash widget ⤵️</div>
      {/* <img className="w-8 items-end" src="../images/10.png" alt="Arrow" /> */}
  </div>
    <div className={`chat-window ${isChatOpen ? 'open' : ''} p-0 z-50 bg-base-100`}>
      
      {isChatOpen && (
        <div className={` ${isChatOpen ? 'open' : ''} `}>
          {step === 1 && (
            <>
                    <div>  <img className='w-full h-32 object-cover rounded-t-xl' src="../images/10.png" /></div>

              <div className='p-6'>
              <h1 className='text-xl  font-bold text-slate-900'>Support Flash in Bitcoin</h1>
              <p className='text-sm mb-4 text-slate-900 mt-1'>By supporting us, you are making payments systems better and smoother for all creators!</p>
              <div className='flex flex-row '>
              <p className='text-sm text-slate-900 font-medium w-full'>Select your amount:</p>
              <div className='flex h-auto items-center justify-end w-full text-xs align-middle text-slate-600  gap-x-1 cursor-pointer '>
              <div 
                onClick={() => handleClick('USD')} 
                className={selectedCurrency === 'USD' ? 'text-slate-900' : 'text-gray-400'}
              >
                USD
              </div>
          <div 
            onClick={() => handleClick('BTC')} 
            className={selectedCurrency === 'BTC' ? 'text-primary' : 'text-gray-400'}
          >
            SATS
          </div>
              </div>
              </div>
              <div className='flex flex-col justify-between space-x-2 mt-1'>
              <div className='flex flex-wrap space-x-2 mt-2'>
                { (selectedCurrency === 'USD' ? usdOptions : btcOptions).map((amount, index) => {
  const emojis = ['🎉', '💪🏼', '😍']; // Replace these with your desired emojis
  return (
    <div 
      key={amount} 
      className={`badge border-base-200  custom-badge rounded-lg badge-outline p-3 cursor-pointer  ${selectedAmount === amount ? ' bg-accent border border-slate-800 text-white' : ' border-1 badge-primary'}`} 
      onClick={() => selectAmount(amount)}
    >
      {emojis[index % emojis.length]} {/* This will select an emoji for each option */}
      {selectedCurrency === 'USD' ? `$${amount}` : <><SatsIcon className='w-4' /> <span>{formatNumber(amount)}</span> </>}
    </div>
  );
})}
        
                  </div>
                </div>
             
              <label className="form-control w-full my-4">
                <CustomInputWithLocalState
                  placeholder={selectedCurrency === 'USD' ? "Custom amount" : "Custom amount"}
                  className={selectedCurrency === 'BTC' ? 'sats-input' : ''}
                  value={customAmount}
                  onChange={handleCustomAmountChange}
                  onBlur={handleCustomAmountBlur}
                  onFocus={handleCustomAmountFocus}
                  selectedCurrency={selectedCurrency}
                />
              </label>
              <button className="btn btn-primary bg-slate-900 w-full" onClick={proceedToPayment}>Continue to payment</button>
              <div className='flex justify-center items-center'>
                  <div className=" tooltip-bottom  justify-center align-middle mt-2" data-tip="You can pay with any Bitcoin Lightning wallet.">
                                    {showError && <p className="text-red-500 mt-0 text-center  text-sm">Please select or enter your amount.</p>}

              <div className="shadow-none mt-0 text-xs p-1 rounded-lg border-none flex items-center">
                <InformationCircleIcon className="h-3 w-3 mr-1" />
                Pay with any Bitcoin Lightning wallet.
                    </div>
                  </div>
                </div>
              </div>
              </>
          )}
          {step === 2 && (
            <>
              <div className='p-6'>
               <h2 className='text-lg font-bold'>Pay with Lightning</h2>
              <p className='badge mt-2'>Amount: {formatCurrency(selectedCurrency === 'USD' ? selectedAmount : customAmountInSats, selectedCurrency)}</p>
              <div className='flex justify-center items-center border border-base-200 rounded-lg my-2'>
                <img className='my-8 w-56' src="../images/qr-test.png" />
                </div>
              <button className="btn btn-primary w-full" >Pay with extension</button>
             <div className='flex justify-center items-center'>
              <button className="text-xs mt-2 text-center justify-center" onClick={goBack}>← Back to amount selection</button>
                </div>
                </div>
            </>
          )}

          <div className="absolute bottom-4 text-xs left-6 text-slate-500">Powered by Flash</div>
        </div>
        )}
        
    </div>
     </div>
  );
};

export default LogoChatToggle;
