import React, { useEffect } from 'react';
import NavbarDark from '../../components/Navbar/NavbarDark';
import Footer from '../../components/Footer/Footer';

const PrivacyPolicy = () => {
    useEffect(() => {
        // Check if the script already exists
        if (!document.getElementById('termly-jssdk')) {
            const script = document.createElement('script');
            script.id = 'termly-jssdk';
            script.src = "https://app.termly.io/embed-policy.min.js";
            script.async = true;
            const tjs = document.getElementsByTagName('script')[0];
            tjs.parentNode.insertBefore(script, tjs);
        }
    }, []);

    return (
        <div className="flex flex-col w-full bg-white relative ">
            <NavbarDark className='w-full' />
            <div className='px-4 md:px-44 mt-8' name="termly-embed" data-id="98e6710c-2dd7-4957-a085-69b24ac5bd54"></div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;