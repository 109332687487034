import React from 'react';
import SignInForm from '../../components/Other/SignInForm';

const LogIn = () => {
    return (
        <div className='flex py-24 align-middle items-center justify-center'>
            <div >
                <SignInForm />
            </div>
            
        </div>
    );
};

export default LogIn;