import React from 'react';

// Single Masonry card component
const MasonryCard = ({ title, description, image, badgeImage, badgeText }) => {
    return (
        <div className="masonry-card border-0 bg-white rounded-lg p-2 pb-4 shadow-lg">

            <img src={image} alt={title} className="w-full  object-cover rounded-lg" />
            <div className='p-6'>
            <div className="badge badge-primary rounded-lg px-3 py-5 text-slate-700 font-medium bg-slate-100 border-0 mb-4 mt-2">
                <img src={badgeImage} alt={badgeText} className="w-5  mr-2" />
                <span>{badgeText}</span>
            </div>
                <h2 className='text-2xl font-bold text-slate-900 mb-2'>{title}</h2>
                <p className='text-lg  text-slate-600'>{description}</p>
            </div>
        </div>
    );
};

// The overall Masonry layout component that wraps individual cards
const MansoCardsAbout = () => {
    const cardsData = [
        { title: 'We never touch your money', description: 'Our platform ensures that all payments go directly to you without any intermediary handling your funds.', image: '/images/about2.png', badgeImage: '/images/icon1.png', badgeText: 'Integrations' },
        { title: 'Realtime analytics and reports', description: 'Gain insightful data through real-time analytics and detailed reports to optimize your business operations.', image: '/images/step4.png', badgeImage: '/images/icon1.png', badgeText: 'Merchant Account' },
        { title: 'Immediate transactions, tiny fees', description: 'Experience swift payment processing with minimal fees for both one-time and recurring B2B transactions.', image: '/images/about1.png', badgeImage: '/images/icon1.png', badgeText: 'Lightning wallets' },
        { title: 'No & Low code integrations', description: 'Effortlessly integrate our payment solutions into your platform with minimal coding required.', image: '/images/about32.png', badgeImage: '/images/icon1.png', badgeText: 'Checkout' },
    ];

    return (
        <div>
        <div className='w-full pr-6 md:w-2/3 pl-6 md:pl-44 mt-12 -mt-12'>
                    <div className='badge mb-4 p-4 border-slate-300 bg-transparent text-slate-600 font-semibold'>Corporate solutions</div>
                    <h2 className="text-5xl text-slate-900 w-full tracking-tight font-bold ">Secure and Flexible Bitcoin Transactions</h2>
          <p className="py-6 text-lg text-slate-700 section-text font-medium">Offer your customers the confidence and convenience of secure, adaptable Bitcoin payments, ensuring a reliable and efficient checkout experience every time.</p>
        </div>
        <div className="masonry-layout px-4 md:px-44">
            <div className="column md:w-1/2 left mb-12 md:mb-0">
                <MasonryCard {...cardsData[0]} />
                <MasonryCard {...cardsData[1]} />
            </div>
            <div className="column md:w-1/2 right mb-12 md:mb-0">
                <MasonryCard {...cardsData[2]} />
                <MasonryCard {...cardsData[3]} />
            </div>
            </div>
            </div>
    );
};

export default MansoCardsAbout;